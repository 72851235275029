import React from 'react'
import styled from '@emotion/styled'
import Link from 'src/components/Link'
import Grid from 'src/components/Grid'
import ThemeSelector from 'src/components/ThemeSelector'
import ScrollEntrance from 'src/components/ScrollEntrance'
import { colors, typography, util } from 'src/styles'
import { Transition } from 'react-transition-group'

const timeout = 800
const timingFunction = 'cubic-bezier(0.44, 0.24, 0.16, 1.00)'

const Wrapper = styled(ThemeSelector)`
	position: fixed;
	width: 100vw;
	// height: calc(100vh - 100px);
	top: 0;
	left: 0;
	right: 0;
	height: calc( 100vh - 100px );
	margin-top: 50px;
	margin-bottom: 50px;
	z-index: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	// align-items: center;
	align-items: stretch;
	// overflow: hidden;
	-webkit-overflow-scrolling: touch;
	background: rgba(0,0,0,0.95);
	transition: opacity ${ timeout }ms ${ timingFunction }, visibility ${ timeout }ms ${ timingFunction };
	// transform: translate3d(110%, 0, 0);
	opacity: 0;
	${ ({ open }) => open === true ? `
		// visibility: visible;
		// display: block;
		opacity: 1;
	` : `
		// visibility: hidden;
		display: none;
	` }
`

const MobileNavLink = styled(Link)`
	${ ({ size }) => size ? `${ typography[size] }` : typography.h2 }
	${ ({ size }) => size === 'smallCaps' ? `
		margin-top: 20px;
	` : `` }
	display: block;
	padding: 2px 0;
	color: ${ colors.textColor };
	span {
		display: inline-block;
	}
`

const MainSection = styled.div`
	// flex-grow: 1;
	// width: 100%;
	// max-height: 60vh;
	// display: flex;
	// flex-direction: column;
	// // align-items: center;
	// align-items: stretch;
	// padding: 0 20px;
	// position: relative;


	width: 100%;
	border-top: 1px solid white;
	border-bottom: 1px solid white;
	padding: 0 20px; 
	min-height: 60vh;
	display: flex;
	flex-direction: column;
	
`

const BottomSection = styled.div`
	flex-grow: 0;
	flex-shrink: 0;
	padding: 4vw 0 6vw;
	p a {
		border-color: transparent;
	}
	b {
		color: ${ colors.mainColor };
	}
`

const NavLinksWrap = styled.div`
	// width: 100%;
	// height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	svg {
		fill: white;
	}
`

const LinkWrap = styled.div`
	// background: salmon;
	&.bottom {
		align-self: flex-end;
		margin-bottom: 20px;
	}

	&.top {
		margin-top: 20px;
	}
`

const TopGrid = styled(Grid)`
	flex-grow: 1;
	svg {
		width: 16px;
	}
`

const MidGrid = styled(Grid)`
	svg {
		height: 16px;
	}
	.left {
		text-align: right;
	}
`

const GridWrap = styled.div`
	flex-grow: 1;
	display: flex;
	svg {
		fill: white;
	}
`

const Divider = styled.div`
 	width: 100%;
 	height: 100%;
 	position: relative;
  &:before {
    content:"";
    width:1px;
    height:100%;
    display:block;
    left: calc(50% + 1px);
    position:absolute;
    background-image: -webkit-linear-gradient(top, #fff, #fff);
    background-image: -moz-linear-gradient(top, #fff, #fff);
    background-image: -ms-linear-gradient(top, #fff, #fff);
    background-image: -o-linear-gradient(top, #fff, #fff);
    background-image: linear-gradient(top, #fff, #fff);
	}
`

const MobileMenu = ({ open,
	toggleMobileMenu,
	navLinks,
}) => {

	const navAbout = navLinks[0]
  const navApply = navLinks[1]
  const navEvents = navLinks[2]
  const navPortal = navLinks[3]
  const navContact = navLinks[4]
  const navProjects = navLinks[5]

  console.log('MobileMenu rendered, open::', open)
  return (
  	<Wrapper open={open} id="mobile-menu">
  		<MainSection>
	      <GridWrap>
      		<TopGrid
			  		small="[1] 10 [1] 10 [1]"
			  		vAlign="top"
		  		>	
      			<LinkWrap className="top" onClick={() => toggleMobileMenu(!open)}>
        			<Link
                to={'/' + navAbout.to.slug}
              >
            		<svg viewBox="0 0 16 72" fill="none">
								<path d="M16 59.096L3.636 59.096L0.335999 62.308L0.336 67.83L3.636 71.042L16 71.042L16 69.326L9.048 69.326L9.048 60.812L16 60.812L16 59.096ZM7.288 60.812L7.288 69.326L4.362 69.326L2.096 67.126L2.096 63.012L4.362 60.812L7.288 60.812ZM12.7 43.9005L9.004 43.9005L8.168 45.5505L7.332 43.9005L3.636 43.9005L0.335999 47.1125L0.335999 55.8465L16 55.8465L16 47.1125L12.7 43.9005ZM6.232 45.6165L7.288 47.6625L7.288 54.1305L2.096 54.1305L2.096 47.8165L4.362 45.6165L6.232 45.6165ZM11.974 45.6165L14.24 47.8165L14.24 54.1305L9.048 54.1305L9.048 47.6625L10.104 45.6165L11.974 45.6165ZM12.7 29.0964L3.636 29.0964L0.335998 32.3084L0.335998 37.8304L3.636 41.0424L12.7 41.0424L16 37.8304L16 32.3084L12.7 29.0964ZM11.974 30.8124L14.24 33.0124L14.24 37.1264L11.974 39.3264L4.362 39.3264L2.096 37.1264L2.096 33.0124L4.362 30.8124L11.974 30.8124ZM12.7 14.1997L0.335997 14.1997L0.335997 15.9157L11.974 15.9157L14.24 18.1157L14.24 22.2077L11.974 24.4077L0.335998 24.4077L0.335998 26.1237L12.7 26.1237L16 22.9117L16 17.4117L12.7 14.1997ZM2.096 0.0775614L0.335997 0.0775615L0.335997 12.0016L2.096 12.0016L2.096 6.89756L16 6.89756L16 5.18156L2.096 5.18156L2.096 0.0775614Z" />
								</svg>
              </Link>
            </LinkWrap>

            <Divider />

            <LinkWrap className="top" onClick={() => toggleMobileMenu(!open)}>
              <Link
                to={'/' + navContact.to.slug}
              >
            		<svg viewBox="0 0 16 93" fill="none">
								<path d="M3.608 12.992L4.84 11.76L1.76 8.79L1.76 4.676L4.026 2.476L11.638 2.476L13.904 4.676L13.904 8.79L10.802 11.782L12.078 12.97L15.664 9.494L15.664 3.972L12.364 0.76L3.3 0.759999L-2.17333e-07 3.972L-4.58707e-07 9.494L3.608 12.992ZM3.3 27.2355L12.364 27.2355L15.664 24.0235L15.664 18.5015L12.364 15.2895L3.3 15.2895L-8.52436e-07 18.5015L-1.09381e-06 24.0235L3.3 27.2355ZM4.026 25.5195L1.76 23.3195L1.76 19.2055L4.026 17.0055L11.638 17.0055L13.904 19.2055L13.904 23.3195L11.638 25.5195L4.026 25.5195ZM-1.89018e-06 42.2422L15.664 42.2422L15.664 40.5262L3.454 40.5262L15.664 31.6602L15.664 30.2962L-1.368e-06 30.2962L-1.44301e-06 32.0122L12.21 32.0122L-1.83055e-06 40.8782L-1.89018e-06 42.2422ZM13.904 56.4477L15.664 56.4477L15.664 44.5237L13.904 44.5237L13.904 49.6277L-2.21301e-06 49.6277L-2.28801e-06 51.3437L13.904 51.3437L13.904 56.4477ZM-3.00109e-06 67.6568L15.664 62.0248L15.664 60.7268L-2.45295e-06 55.1168L-2.53084e-06 56.8988L4.532 58.5488L4.532 64.2028L-2.92223e-06 65.8528L-3.00109e-06 67.6568ZM6.292 63.5868L6.292 59.1868L12.408 61.3648L6.292 63.5868ZM3.608 80.9772L4.84 79.7452L1.76 76.7752L1.76 72.6612L4.026 70.4612L11.638 70.4612L13.904 72.6612L13.904 76.7752L10.802 79.7672L12.078 80.9552L15.664 77.4792L15.664 71.9572L12.364 68.7452L3.3 68.7452L-3.18906e-06 71.9572L-3.43043e-06 77.4792L3.608 80.9772ZM13.904 92.9462L15.664 92.9462L15.664 81.0222L13.904 81.0222L13.904 86.1262L-3.80841e-06 86.1262L-3.88342e-06 87.8422L13.904 87.8422L13.904 92.9462Z"/>
								</svg>
              </Link>
            </LinkWrap>
          </TopGrid>
        </GridWrap>
        <GridWrap>
      		<MidGrid
      			small="1 [10] 1 [1] 1 [10] 1"
			  		vAlign="middle"
      		>
      			<LinkWrap className="left" onClick={() => toggleMobileMenu(!open)}>
        			<Link
                to={'/' + navPortal.to.slug}
              >
            		<svg viewBox="0 0 84 16" fill="none" >
								<path d="M12.904 7.332V3.636L9.692 0.336H0.958V16H2.674V9.048H9.582L12.904 7.332ZM11.188 6.232L9.142 7.288H2.674V2.096H8.988L11.188 4.362V6.232ZM27.4288 12.7V3.636L24.2168 0.336H18.6948L15.4828 3.636V12.7L18.6948 16H24.2168L27.4288 12.7ZM25.7128 11.974L23.5128 14.24H19.3988L17.1988 11.974V4.362L19.3988 2.096H23.5128L25.7128 4.362V11.974ZM42.4356 16V12.062L38.8496 9.048H39.1136L42.4356 7.332V3.636L39.2236 0.336H30.4896V16H32.2056V9.048H36.2096L40.7196 12.898V16H42.4356ZM40.7196 6.232L38.6736 7.288H32.2056V2.096H38.5196L40.7196 4.362V6.232ZM55.0512 2.096V0.336H43.1272V2.096H48.2312V16H49.9472V2.096H55.0512ZM68.2933 16V3.636L65.0813 0.336H59.5593L56.3473 3.636V16H58.0633V9.048H66.5773V16H68.2933ZM66.5773 7.288H58.0633V4.362L60.2633 2.096H64.3773L66.5773 4.362V7.288ZM83.4888 16V14.24H73.2588V0.336H71.5428V16H83.4888Z"/>
								</svg>
              </Link>
            </LinkWrap>
            <Divider />
            <LinkWrap className="middle" onClick={() => toggleMobileMenu(!open)}>
              <Link
                to={'/' + navEvents.to.slug}
              >
            		<svg viewBox="0 0 82 16" fill="none">
								<path d="M11.9677 16V14.24H1.73768V9.048H7.65568V7.288H1.73768V2.096H11.9677V0.336H0.021675V16H11.9677ZM25.6909 4.076V0.336H23.9529V3.614L19.7069 13.25L15.4609 3.614V0.336H13.7449V4.076L19.0909 16H20.3449L25.6909 4.076ZM40.3821 16V14.24H30.1521V9.048H36.0701V7.288H30.1521V2.096H40.3821V0.336H28.4361V16H40.3821ZM54.5892 16V0.336H52.9612V10.698L44.2492 3.35V0.336H42.6432V16H44.2712L44.2492 5.616L52.9612 12.986V16H54.5892ZM68.7947 2.096V0.336H56.8707V2.096H61.9747V16H63.6907V2.096H68.7947ZM81.8168 3.922L78.3408 0.336H72.7968L69.5848 3.636V7.332L72.9728 9.048H77.8348L79.8148 10.082V11.974L77.6148 14.24H73.5228L70.5308 11.138L69.2988 12.414L72.7968 16H78.3408L81.5308 12.7V9.004L78.2528 7.288H73.3688L71.3008 6.232V4.362L73.5228 2.096H77.6148L80.6068 5.198L81.8168 3.922Z"/>
								</svg>
              </Link>
            </LinkWrap>
      		</MidGrid>
      	</GridWrap>
      	<GridWrap>
      		<TopGrid
	      		small="[1] 10 [1] 10 [1]"
			  		vAlign="top"
      		>

      			<LinkWrap className="bottom" onClick={() => toggleMobileMenu(!open)}>
	      			<Link
	              to={'/' + navApply.to.slug}
	            >
	          		<svg viewBox="0 0 16 68" fill="none">
								<path d="M16 55.096L3.636 55.096L0.335999 58.308L0.336 63.83L3.636 67.042L16 67.042L16 65.326L9.048 65.326L9.048 56.812L16 56.812L16 55.096ZM7.288 56.812L7.288 65.326L4.362 65.326L2.096 63.126L2.096 59.012L4.362 56.812L7.288 56.812ZM7.332 39.9005L3.636 39.9005L0.335999 43.1125L0.335999 51.8465L16 51.8465L16 50.1305L9.048 50.1305L9.048 43.2225L7.332 39.9005ZM6.232 41.6165L7.288 43.6625L7.288 50.1305L2.096 50.1305L2.096 43.8165L4.362 41.6165L6.232 41.6165ZM7.332 25.1777L3.636 25.1777L0.335998 28.3897L0.335998 37.1237L16 37.1237L16 35.4077L9.048 35.4077L9.048 28.4997L7.332 25.1777ZM6.232 26.8937L7.288 28.9397L7.288 35.4077L2.096 35.4077L2.096 29.0937L4.362 26.8937L6.232 26.8937ZM16 10.4549L14.24 10.4549L14.24 20.6849L0.335998 20.6849L0.335998 22.4009L16 22.4009L16 10.4549ZM4.274 0.727811L0.335997 0.727811L0.335997 2.46581L3.438 2.46581L6.958 6.71181L3.438 10.9578L0.335997 10.9578L0.335997 12.6738L4.274 12.6738L8.498 7.56981L16 7.56981L16 5.85381L8.498 5.85381L4.274 0.727811Z"/>
								</svg>
	            </Link>
            </LinkWrap>

            <Divider />

            <LinkWrap className="bottom" onClick={() => toggleMobileMenu(!open)}>
	            <Link
	              to={'/' + navProjects.to.slug}
	            >
	          		<svg viewBox="0 0 16 111" fill="none">
								<path d="M8.668 12.904L12.364 12.904L15.664 9.692L15.664 0.958L-8.55869e-08 0.957999L-1.60596e-07 2.674L6.952 2.674L6.952 9.582L8.668 12.904ZM9.768 11.188L8.712 9.142L8.712 2.674L13.904 2.674L13.904 8.988L11.638 11.188L9.768 11.188ZM-1.26478e-06 27.9348L7.414 25.2068L8.668 27.6268L12.364 27.6268L15.664 24.4148L15.664 15.6808L-7.29142e-07 15.6808L-8.0415e-07 17.3968L6.952 17.3968L6.952 23.5568L-1.18496e-06 26.1088L-1.26478e-06 27.9348ZM9.768 25.9108L8.712 23.8648L8.712 17.3968L13.904 17.3968L13.904 23.7108L11.638 25.9108L9.768 25.9108ZM3.3 42.1516L12.364 42.1516L15.664 38.9396L15.664 33.4176L12.364 30.2056L3.3 30.2056L-1.50444e-06 33.4176L-1.74582e-06 38.9396L3.3 42.1516ZM4.026 40.4356L1.76 38.2356L1.76 34.1216L4.026 31.9216L11.638 31.9216L13.904 34.1216L13.904 38.2356L11.638 40.4356L4.026 40.4356ZM3.3 56.1684L15.664 56.1684L15.664 49.3484L13.904 49.3484L13.904 54.4524L4.026 54.4524L1.76 52.2524L1.76 48.1604L4.862 45.1464L3.586 43.9364L-2.11713e-06 47.4344L-2.35851e-06 52.9564L3.3 56.1684ZM-3.1585e-06 71.2581L1.76 71.2581L1.76 61.0281L6.952 61.0281L6.952 66.9461L8.712 66.9461L8.712 61.0281L13.904 61.0281L13.904 71.2581L15.664 71.2581L15.664 59.3121L-2.63633e-06 59.3121L-3.1585e-06 71.2581ZM3.608 85.0592L4.84 83.8272L1.76 80.8572L1.76 76.7432L4.026 74.5432L11.638 74.5432L13.904 76.7432L13.904 80.8572L10.802 83.8492L12.078 85.0372L15.664 81.5612L15.664 76.0392L12.364 72.8272L3.3 72.8272L-3.36749e-06 76.0392L-3.60886e-06 81.5612L3.608 85.0592ZM13.904 97.0282L15.664 97.0282L15.664 85.1042L13.904 85.1042L13.904 90.2082L-3.98684e-06 90.2082L-4.06185e-06 91.9242L13.904 91.9242L13.904 97.0282ZM12.078 110.05L15.664 106.574L15.664 101.03L12.364 97.8183L8.668 97.8183L6.952 101.206L6.952 106.068L5.918 108.048L4.026 108.048L1.76 105.848L1.76 101.756L4.862 98.7643L3.586 97.5323L-4.45989e-06 101.03L-4.70222e-06 106.574L3.3 109.764L6.996 109.764L8.712 106.486L8.712 101.602L9.768 99.5343L11.638 99.5343L13.904 101.756L13.904 105.848L10.802 108.84L12.078 110.05Z"/>
								</svg>
	            </Link>
            </LinkWrap>
      		</TopGrid>
    		</GridWrap>
    		</MainSection>
    </Wrapper>

  )
}

export default MobileMenu
